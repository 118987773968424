.MainHome {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  min-height: calc(var(--vh, 1vh) * 100 - 155.001px);
  margin-bottom: 97px;
}

.mainMenu {
  width: 100vw;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.imgL1,
.imgL2 {
  width: 17.5vw;
  height: 70%;
  position: absolute; /* Altera para posicionamento absoluto */
}

.imgL1 {
  top: 5px;
  left: -1vw; /* Posiciona a imagem no canto superior esquerdo */
  transform: scaleY(-1);
  margin-left: 1vw;
}

.imgL2 {
  bottom: 5px;
  right: -1vw; /* Posiciona a imagem no canto inferior direito */
  transform: scaleX(-1);
  margin-right: 1vw;
}

.MainHome h1 {
  font-size: 50px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  text-shadow: 4px 4px 4px #005eb8;
  text-align: center;
  z-index: 999;
}

.spaceSection {
  background: linear-gradient(
    to bottom,
    whitesmoke 0%,
    #d3d3d3 25%,
    #b7b6b6 50%,
    #d3d3d3 75%,
    whitesmoke 100%
  );
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sectionTitle {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  height: 45px;
  width: 45vw;
  background: linear-gradient(to bottom, #0066cc, #005eb8, #007bff, #006b99);
  color: white;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 30px;
  border: 1.75px solid black;
}

.firstLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 5px;
}

.cardsControl {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.btn-link {
  margin-bottom: 30px;
  margin-right: 50px;
  margin-left: 50px;
  text-decoration: none;
}

.cardMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  border: 5px solid white;
  width: 37.5vw;
  height: 400px;
  padding: 0px 3px 0px 3px;
  border-radius: 15px;
  text-align: center;
  background: linear-gradient(to bottom, #0066cc, #005eb8, #007bff, #006b99);
}

.cardMenu h2 {
  margin-bottom: 7.5%;
  color: white;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  font-size: 35px;
}

.cardMenu img {
  width: 70%;
  height: 75%;
}

@media (max-width: 1200px) {
  .cardMenu {
    width: 42vw;
  }
  .btn-link {
    margin-right: 30px;
    margin-left: 30px;
  }
  .cardMenu {
    height: 385px;
  }

  .sectionTitle {
    width: 50vw;
  }
}

@media (max-width: 920px) {
  .MainHome h1 {
    font-size: 45px;
  }
  .imgL1,
  .imgL2 {
    height: 60%;
  }
  .cardMenu h2 {
    font-size: 32px;
  }
  .cardMenu {
    height: 350px;
  }
  .sectionTitle {
    width: 60vw;
  }
}

@media (max-width: 800px) {
  .cardMenu {
    width: 80vw;
    height: 450px;
  }
  .btn-link {
    margin-right: 0px;
    margin-left: 0px;
  }
  .firstLinks {
    padding-top: 15px;
  }
}

@media (max-width: 720px) {
  .MainHome h1 {
    font-size: 40px;
  }
  .sectionTitle {
    font-size: 28px;
    width: 70vw;
  }
  .imgL1,
  .imgL2 {
    height: 50%;
  }
  .cardMenu {
    width: 85vw;
    height: 400px;
  }
  .cardMenu h2 {
    font-size: 30px;
  }
  .btn-link {
    margin-bottom: 20px;
  }
  .firstLinks {
    padding-top: 10px;
  }
}

@media (max-width: 620px) {
  .MainHome h1 {
    font-size: 37px;
  }
  .imgL1,
  .imgL2 {
    height: 35%;
    width: 20vw;
  }
  .imgL1 {
    margin-left: 2vw;
  }

  .imgL2 {
    margin-right: 3.2vw;
  }

  .sectionTitle {
    font-size: 25px;
    width: 75vw;
  }
  .cardMenu {
    width: 88vw;
    height: 380px;
  }
  .firstLinks {
    padding-top: 5px;
  }
}

@media (max-width: 550px) {
  .MainHome h1 {
    font-size: 30px;
  }
  .imgL1,
  .imgL2 {
    height: 30%;
    width: 27vw;
  }
  .cardMenu {
    width: 92vw;
    height: 370px;
  }
  .cardMenu h2 {
    font-size: 29px;
  }
  .firstLinks {
    padding-top: 0px;
  }
}

@media (max-width: 450px) {
  .cardMenu {
    width: 93vw;
    height: 350px;
  }
  .cardMenu h2 {
    font-size: 28px;
  }
  .btn-link {
    margin-bottom: 15px;
  }
}

@media (max-width: 425px) {
  .cardMenu {
    width: 93vw;
    height: 325px;
  }
  .cardMenu h2 {
    font-size: 27px;
  }
  .btn-link {
    margin-bottom: 13.5px;
  }
}

@media (max-width: 400px) {
  .sectionTitle {
    font-size: 22px;
    width: 85vw;
  }
  .cardMenu {
    height: 300px;
  }
  .cardMenu h2 {
    font-size: 26px;
  }
  .btn-link {
    margin-bottom: 12px;
  }
}

@media (max-width: 375px) {
  .cardMenu {
    height: 290px;
  }
}

@media (max-width: 350px) {
  .cardMenu {
    height: 280px;
  }
}
