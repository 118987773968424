.bottomPubli {
    position: fixed;
    bottom: 0;
    height: 80px;
    width: auto;
    display: flex;
    justify-content: flex-start;
}

.bottomPubli h3 {
    font-size: 30px;
    margin-left: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.bottomPubli a {
    text-decoration: none;
}

#bottomPubliTitle {
    text-align: center;
    font-size: 30px;
    color: white;
    text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
}

.bottomPubli1, .bottomPubli2, .bottomPubli3{
    background: linear-gradient(to bottom, #0066cc, #005eb8, #007bff, #006b99);
}

.bottomPubli1 {
    width: 80vw;
    height: 80px;
    border-top: 3px solid white;
    color: white;
    text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.links-bottom-publi {
    display: flex;
    justify-content: space-between;
    width: 90vw;
    z-index: 999;
    position: relative;
    left: 3.5vw;
}

.bottomPubli2 {
    width: 10vw;
    height: 80px;
}

.closeBottomPubli {
    width: 100%;
    height: 30px;
    position: relative;
    bottom: 28px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #0066cc;
    border: 3px solid white;
    color: white;
    text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
    font-size: 15px;
    border-bottom: transparent;
    cursor: pointer;
}

.bottomPubli3 {
    width: 10vw;
    height: 80px;
    border-top: 3px solid white;
}

@media (max-width:900px){
    .bottomPubli1 {
        width: 70vw;
    }
    
    .bottomPubli2 {
        width: 20vw;
    }

    #bottomPubliTitle {
        font-size: 20px;
    }
}

@media (max-width:600px){
    .bottomPubli1 {
        width: 60vw;
    }
    
    .bottomPubli2 {
        width: 30vw;
    }

    #bottomPubliTitle {
        font-size: 15px;
    }

    .closeBottomPubli {
        font-size: 15px;
    }
}

@media (max-width:600px){
    .bottomPubli1 {
        width: 58vw;
    }
    
    .bottomPubli2 {
        width: 32vw;
    }

    #bottomPubliTitle {
        font-size: 12px;
    }

    .closeBottomPubli {
        font-size: 13px;
    }
}