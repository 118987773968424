.centerPubli {
    position: fixed;
    top: 100px;
    right: calc(50% - 230px);
    background: linear-gradient(to bottom, #0066cc, #005eb8, #007bff, #006b99);
    height: 500px;
    width: 460px;
    border: 5px solid white;
    border-radius: 15px;
    z-index: 999;
    animation: fadeIn 0.7s forwards;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.centerPubli a {
    text-decoration: none;
}

#centerPubliTitle {
    text-align: center;
    font-size: 30px;
    color: white;
    text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
    margin-top: 12px
}

#closeCenterPubli {
    position: absolute;
    top: 8px;
    right: 15px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 2px;
    padding-bottom: 5px;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red !important;
    background: transparent;
    border: 2px solid white;
    border-radius: 3px;
    cursor: pointer;
}

@media (max-width:700px) {
    .centerPubli {
        height: 400px;
        width: 368px;
        right: calc(50% - 184px);
    }

    #centerPubliTitle {
        font-size: 20px;
    }
}

@media (max-width: 500px) {  
    .centerPubli {  
        height: 370px;  
        width: 340.4px; 
        right: calc(50% - 170.2px);  
    }  
}  

@media (max-width: 360px) {  
    .centerPubli {  
        height: 320px;  
        width: 294.4px; 
        right: calc(50% - 147.2px);
    }  
}  