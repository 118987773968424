.dashboard {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 115px;
}

.dashboard h2 {
  font-size: 2.2em;
  margin-bottom: 0.5em;
  color: whitesmoke;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
}

.dashboard p {
  color: #aaa;
  margin-bottom: 1em;
}

.btn {
  text-decoration: none;
}

.noposts {
  text-align: center;
}

.noposts p {
  margin-bottom: 1.5em;
}

.noposts a {
  padding: 10px 25px;
}

.post_header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  border-bottom: 2px solid #ccc;
  width: 80%;
  padding: 10px;
  color: whitesmoke;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
}

.post_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  width: 80%;
  padding: 10px;
}

.post_row p {
  color: whitesmoke;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
  text-align: start;
}

.post_row button,
.post_row a {
  margin: 0 5px;
  height: 30px;
  width: 100px;
  font-size: 0.7em;
  text-decoration: none;
  text-shadow: 1px 1px 1px white, -1px -1px 1px white, -1px 1px 1px white,
    1px -1px 1px white;
}

.post_row button:hover,
.post_row a:hover {
  text-shadow: none;
}

@media (max-width: 820px) {
  .post_header {
    width: 90%;
  }
  .post_row {
    width: 90%;
  }
  .post_row button,
  .post_row a {
    margin: 0 5px;
    height: 25px;
    width: 60px;
    font-size: 0.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1.5px;
    margin-top: 1.5px;
    position: relative;
    left: 15%;
  }
}

@media (max-width: 500px) {
  .post_header {
    width: 95%;
  }
  .post_row {
    width: 95%;
  }
  .post_row p {
    font-size: 13px;
  }
}
