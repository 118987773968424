.gameOver {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  bottom: 5vh;
}

.gameOver h1 {
  color: #fff;
}

.gameOver h2 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #fff;
}

.gameOver h2 span {
  color: #ecfa00;
}

.gameOver button {
  background: linear-gradient(to bottom, #0066cc, #005eb8, #007bff, #006b99);
  color: #fff;
  padding: 0 45px;
  border: 2px solid #fff;
  border-radius: 25px;
  height: 50px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
  transition: 0.4s;
}
