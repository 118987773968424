/* Estilos gerais */
.game {
  width: 100vw;
  margin: auto;
  padding: 20px;
  color: white;
  border-radius: 10px;
  position: relative;
  text-align: center;
  height: calc(var(--vh, 1vh) * 100 - 155px);
}

.game button {
  background: linear-gradient(to bottom, #0066cc, #005eb8, #007bff, #006b99);
  color: #fff;
  padding: 0 45px;
  border: 2px solid #fff;
  border-radius: 25px;
  height: 50px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
  transition: 0.4s;
}

.game h1 {
  font-size: 2.5em;
}

.points span {
  font-weight: bold;
}

.tip span {
  color: #ecfa00;
}

.wordContainer {
  margin: 1.5em auto;
  padding: 1.5em;
  border: 20px solid #ecfa00;
  display: inline-block;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.1em;
  max-width: 60vw;
  min-width: 20vw;
  white-space: nowrap;
}

.letter,
.blankSquare {
  font-size: 70px;
  line-height: 100px; /* Alinhar ao tamanho do elemento */
  border: 3px solid #000;
  height: 100px;
  width: 100px;
  text-transform: uppercase;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  display: inline-block;
  vertical-align: top; /* Adicionado para alinhar ao topo */
}

.letterContainer p {
  margin-bottom: 0.8em;
}

.letterContainer form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.letterContainer input {
  height: 50px;
  width: 50px;
  font-size: 2em;
  text-align: center;
  margin-right: 0.5em;
}

.wrongLetterContainer {
  margin-top: 10px;
}

@media (max-width: 1250px) {
  .wordContainer {
    max-width: 70vw;
  }

  .letter,
  .blankSquare {
    font-size: 60px;
    height: 80px;
    width: 80px;
    line-height: 80px; /* Atualizado para alinhar com o tamanho do elemento */
  }
}

@media (max-width: 1100px) {
  .wordContainer {
    max-width: 80vw;
  }

  .letter,
  .blankSquare {
    font-size: 50px;
    height: 70px;
    width: 70px;
    line-height: 70px; /* Atualizado para alinhar com o tamanho do elemento */
  }
}

/* Estilos responsivos */
@media (max-width: 950px) {
  .game h1 {
    font-size: 2em;
  }

  .wordContainer {
    max-width: 95vw;
    margin: 1em;
    padding: 1em;
    border: 15px solid #ecfa00;
  }

  .letter,
  .blankSquare {
    font-size: 40px;
    height: 60px;
    width: 60px;
    line-height: 60px; /* Atualizado para alinhar com o tamanho do elemento */
  }

  .letterContainer input {
    height: 50px;
    width: 60px;
    font-size: 1.4em;
    margin-right: 0.5em;
  }
}

@media (max-width: 740px) {
  .game h1 {
    font-size: 1.5em;
  }

  .wordContainer {
    margin: 0.5em;
    padding: 0.5em;
    border: 10px solid #ecfa00;
  }

  .letter,
  .blankSquare {
    font-size: 30px;
    height: 45px;
    width: 45px;
    line-height: 45px; /* Atualizado para alinhar com o tamanho do elemento */
  }

  .letterContainer input {
    height: 50px;
    width: 50px;
    font-size: 1em;
    margin-right: 1em;
  }
}

@media (max-width: 580px) {
  .game h1 {
    font-size: 1.2em;
    margin: 0.5em 0; /* Diminui o espaçamento vertical */
  }

  .points {
    margin: 0.5em 0; /* Diminui o espaçamento vertical */
  }

  .tip {
    margin: 0.5em 0; /* Diminui o espaçamento vertical */
  }

  .wordContainer {
    margin: 0.5em 0;
    padding: 0.5em;
    border: 5px solid #ecfa00;
  }

  .letter,
  .blankSquare {
    font-size: 20px;
    height: 35px;
    width: 35px;
    line-height: 35px; /* Atualizado para alinhar com o tamanho do elemento */
  }

  .letterContainer p {
    margin-bottom: 0.5em; /* Diminui o espaçamento vertical */
  }

  .letterContainer input {
    height: 40px;
    width: 40px;
    font-size: 0.8em;
    margin-right: 0.5em;
  }

  .wrongLetterContainer {
    margin-top: 0.5em; /* Diminui o espaçamento vertical */
  }
}

@media (max-width: 480px) {
  .game {
    position: relative;
    right: 0.8vw;
  }

  .letter,
  .blankSquare {
    font-size: 18px;
    height: 30px;
    width: 30px;
    line-height: 30px; /* Atualizado para alinhar com o tamanho do elemento */
  }
}

@media (max-width: 600px) and (max-height: 800px) {
  .game h1 {
    font-size: 1em;
  }
  .letterContainer p {
    font-size: 0.8em;
  }
  .game h3,
  .game p {
    font-size: 0.8em;
  }
  .game button {
    font-size: 0.8em;
    padding: 0 30px;
    height: 42px;
  }
  .letterContainer input {
    font-size: 0.8em;
    height: 42px;
  }
}
