.productCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 280px;
  height: 450px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 12px;
  background-color: #fff;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 25px;
}

.productCard:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.productCard img {
  width: 95%;
  height: 70%;
  border-radius: 8px;
}

.productCard h2 {
  font-size: 20px;
  color: #333;
  text-align: center;
}

.productCard a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
  text-align: center;
}

.productCard a:hover {
  background-color: #0056b3;
}

@media (max-width: 700px) {
  .productCard {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media (max-width: 600px) {
  .productCard {
    width: 180px;
    height: 300px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .productCard h2 {
    font-size: 12px;
  }
  .productCard a {
    font-size: 13px;
  }
}

@media (max-width: 380px) {
  .productCard {
    width: 150px;
    height: 260px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .productCard h2 {
    font-size: 10px;
  }
  .productCard a {
    font-size: 11px;
  }
}

@media (max-width: 400px) and (max-height: 550px) {
  .productCard h2 {
    font-size: 9px;
  }
  .productCard a {
    font-size: 10px;
  }
}
