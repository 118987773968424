.blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(var(--vh, 1vh) * 100 - 155.001px);
  margin-bottom: 97px;
}

.blog h1 {
  margin-top: 25px;
  color: white;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
  text-align: center;
}

.blog form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.blog input {
  width: 60vw;
  height: 35px;
  border-radius: 15px;
  border: 2px solid black;
  padding: 3px;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 17px;
}

.blog button {
  width: 30vw;
  height: 35px;
  border-radius: 15px;
  background: linear-gradient(to bottom, #0066cc, #005eb8, #007bff, #006b99);
  color: #fff;
  cursor: pointer;
  border: 2px solid black;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
  margin-bottom: 10px;
}

.posts-container {
  display: flex; /* Alinha os posts lado a lado */
  flex-wrap: wrap; /* Permite que os posts quebrem de linha se não houver espaço */
  justify-content: center; /* Alinha os posts ao centro */
}

.noposts {
  text-align: center;
}

.noposts p {
  margin-bottom: 1.5em;
}

.noposts a {
  padding: 10px 25px;
}

@media (max-width: 600px) {
  .blog h1 {
    font-size: 22px;
  }
  .blog input {
    width: 80vw;
    margin-bottom: 7.5px;
  }
  .blog button {
    width: 60vw;
  }
}

@media (max-width: 390px) {
  .blog h1 {
    font-size: 19px;
  }
}
