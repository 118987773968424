/* Loading.css */
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35); /* Fundo branco semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Certifique-se de que o loading fique acima de outros elementos */
}

.loader {
  border: 8px solid #f3f3f3; /* Cor clara para a borda */
  border-top: 8px solid #3498db; /* Cor da bolinha girando */
  border-radius: 50%;
  width: 50px; /* Tamanho da bolinha */
  height: 50px;
  animation: spin 1s linear infinite; /* Animação de rotação */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
