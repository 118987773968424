.post_detail {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  border: 5px solid white;
  width: 40vw;
  padding: 0px 3px 0px 3px;
  border-radius: 15px;
  text-align: center;
  background: linear-gradient(to bottom, #0066cc, #005eb8, #007bff, #006b99);
  margin-right: 25px;
  margin-left: 25px;
}

.post_detail h1 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #fff;
}

.post_detail h2 {
  font-size: 18px;
  margin-top: 10px;
  color: #fff;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
}

.btnLerMais {
  width: 20vw;
  height: 35px;
  border-radius: 15px;
  background: linear-gradient(to bottom, #f9f9f9, #f5f5f5, #e0e0e0);
  color: #000;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 2px solid black;
  margin-bottom: 30px;
}

.post_detail img {
  width: 80%;
  height: auto;
  border-radius: 15px;
  border: 1px solid white;
}

.post_detail .tags {
  margin-bottom: 1.2em;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.tags p {
  margin-right: 3px;
  margin-left: 3px;
}

.tags span {
  font-weight: bold;
}

@media (max-width: 800px) {
  .post_detail {
    width: 85vw;
    margin-bottom: 15px;
    margin-top: 15px;
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media (max-width: 600px) {
  .post_detail img {
    width: 90%;
  }
  .btnLerMais {
    width: 40vw;
  }
  .post_detail p {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .post_detail {
    width: 90vw;
  }
}
